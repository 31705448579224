import DateSeparator from '@/components/chat/date-separator.tsx';
import { formatDateSeparatorString } from './utils.ts';
import ChatResponse from '@/components/chat/chat-response.tsx';
import { IMessage } from '@/components/chat/types.ts';
import { RefObject } from 'react';
import ReferralCard from '@/components/chat/referral/referral-card.tsx';
import EJCard from '@/components/chat/referral/ej-card.tsx';

interface ChatFeedProps {
  lastUserMessageRef: RefObject<HTMLDivElement>;
  loading: boolean;
  messages: IMessage[];
}

const ChatFeed = ({ lastUserMessageRef, loading, messages }: ChatFeedProps) => {
  let lastDateString = '';

  return messages.map((item: IMessage, index: number) => {
    const isUser = item.user_generated_message;
    const messageDate = new Date(item.created_at);
    const messageDateStr = messageDate.toISOString().split('T')[0];
    const formattedDateString = formatDateSeparatorString(messageDate);

    let dateSeparator = null;

    const referralSelector = 'QUALIFIED_FOR_NDR';
    const edwardJonesSelector = 'QUALIFIED_FOR_EJ'; // 529 plans card

    if (index === 0 || lastDateString !== messageDateStr) {
      const testId = `date-separator-${messageDateStr}`;
      dateSeparator = (
        <DateSeparator date={formattedDateString} testId={testId} />
      );
      lastDateString = messageDateStr;
    }

    if (item.message_content.includes(referralSelector)) {
      return <ReferralCard />;
    }

    if (item.message_content.includes(edwardJonesSelector)) {
      return <EJCard />;
    }

    return (
      <div key={`msg-${index}`}>
        {dateSeparator}
        <div
          className={`chat-message py-1 ${isUser ? 'flex justify-end' : ''}`}
        >
          <div className={`flex ${isUser ? 'max-w-[60%]' : ''}`}>
            <ChatResponse
              lastUserMessageRef={lastUserMessageRef}
              type={isUser ? 'user' : 'bot'}
              message={item.message_content}
              loading={loading && index === messages.length - 1 && !isUser}
            />
          </div>
        </div>
      </div>
    );
  });
};

export default ChatFeed;
