import face from '@/assets/images/face.svg';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';
import rehypeRaw from 'rehype-raw';
import { defaultSchema } from 'hast-util-sanitize';
import { RefObject } from 'react';

const sanitizeSchema = {
  ...defaultSchema,
  attributes: {
    ...(defaultSchema.attributes ?? {}),
    a: [...(defaultSchema.attributes?.a ?? []), 'target', 'rel'],
  },
};

export default function ChatResponse({
  type,
  message,
  lastUserMessageRef,
  loading = false,
}: {
  lastUserMessageRef?: RefObject<HTMLDivElement>;
  message: string;
  loading?: boolean;
  type: 'user' | 'bot';
}) {
  const baseStyles = 'chat-response p-4 rounded-lg text-dark font-medium';
  if (type === 'user') {
    return (
      <div
        ref={lastUserMessageRef}
        className={`${baseStyles} bg-white scroll-mt-20`}
      >
        <div>{message}</div>
      </div>
    );
  }

  return (
    <div className={`${baseStyles} flex items-start`}>
      <img className="mr-4" src={face} alt="chat user" />
      {loading && !message ? (
        <LoadingSpinner className="mt-1" color="hsl(var(--input-foreground))" />
      ) : (
        <div className="flex flex-col gap-2">
          <ReactMarkdown
            className="markdown"
            rehypePlugins={[rehypeRaw, [rehypeSanitize, sanitizeSchema]]} // Use rehypeRaw and custom schema
          >
            {message}
          </ReactMarkdown>
        </div>
      )}
    </div>
  );
}
