import { IFinchConnection } from '@/types.ts';
import { Card } from '@/components/ui/card.tsx';
import { Badge } from '@/components/ui/badge.tsx';
import { BiError } from 'react-icons/bi';
import { Button } from '@/components/ui/button.tsx';
import { format } from 'date-fns';

interface FinchReauthenticateProps {
  connection: IFinchConnection | null;
  url: string;
}
const FinchReauthenticate = ({ connection, url }: FinchReauthenticateProps) => {
  const lastSyncDate = connection?.data_refreshed_at;

  return (
    <Card>
      <div className="flex justify-between items-center">
        <div className="flex gap-x-2 items-center">
          <h1 className="text-xl font-semibold capitalize">
            {connection?.finch_provider_id}
          </h1>
          <Badge variant="error">Disconnected</Badge>
        </div>
        {lastSyncDate ? (
          <p className="font-sm font-light">
            Last Sync{' '}
            <span className="font-semibold">
              {format(lastSyncDate, 'MM/dd/yyyy')}
            </span>
          </p>
        ) : null}
      </div>
      <div className="flex items-center gap-x-2 border-l-4 border-error pl-2 mt-3">
        <BiError size={24} className="text-error" />
        <div>
          <p>
            Your connection has been disconnected. Employee data cannot be
            updated until you reconnect.
          </p>
          <Button
            variant="link"
            size="empty"
            className="underline font-medium"
            onClick={() => window.open(url)}
          >
            Reconnect Payroll
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default FinchReauthenticate;
