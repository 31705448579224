import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';

interface FinchDetailsModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const FinchDetailsModal = ({ open, onOpenChange }: FinchDetailsModalProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen-lg">
        <DialogTitle className="mb-2">
          Learn About Payroll Integrations
        </DialogTitle>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger className="font-medium">
              What does it mean to import employees through my payroll system?
            </AccordionTrigger>
            <AccordionContent>
              <p>
                Peopled imports employees by making an integration between your
                payroll system and Peopled. This enables us to access the
                employee census data housed in your payroll system. We utilize
                the census data to create, update, and add employee accounts as
                your organization changes over time.
              </p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger className="font-medium">
              What are the benefits of integrating to my payroll system?{' '}
            </AccordionTrigger>
            <AccordionContent>
              <p className="mb-2">
                By integrating to your payroll system, manual administrative
                functions become automated, greatly reducing your operational
                burden and errors caused by data discrepancies between systems.
                Other benefits include:
              </p>
              <ul className="list-disc pl-3">
                <li>Reduced security risks from manually sharing PII</li>
                <li>
                  Streamlined onboarding experiences for employees, getting them
                  access as soon as they are hired, and
                </li>
                <li>Automated removal of terminated employees.</li>
              </ul>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger className="font-medium">
              What and who is Finch?
            </AccordionTrigger>
            <AccordionContent>
              Peopled integrates to your payroll system through Finch, a
              third-party service provider with expertise in connecting benefit
              providers and payroll systems. Finch can only connect to your
              payroll system when you explicitly provide them with permission to
              do so. You always remain in control of that permission and can
              revoke it at any time. Finch stresses data security and encrypts
              your data in transit and at rest. You can learn more about Finch
              here.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </DialogContent>
    </Dialog>
  );
};

export default FinchDetailsModal;
