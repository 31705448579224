import { Card } from '@/components/ui/card.tsx';
import { Badge } from '@/components/ui/badge.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useState } from 'react';

import FinchDetailsModal from '@/routes/dashboard/employees/finch/finch-details-modal.tsx';
import { IFinchConnection, IFinchLogin } from '@/types.ts';

interface FinchDetailsProps {
  connection: IFinchConnection | null;
  loginStatus: IFinchLogin;
  url: string;
}

const FinchDetails = ({ url }: FinchDetailsProps) => {
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  return (
    <>
      <FinchDetailsModal
        open={showLearnMoreModal}
        onOpenChange={setShowLearnMoreModal}
      />
      <Card>
        <div className="flex gap-x-2 items-center">
          <h1 className="text-xl font-semibold">
            Import employees from your payroll system
          </h1>
          <Badge variant="success">Recommended</Badge>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="w-2/3">
            <p>
              Save time and simplify employee management by automatically
              connecting your payroll system using Finch.
            </p>
          </div>
          <div>
            <Button onClick={() => window.open(url, '_self')}>
              Import Employees
            </Button>
          </div>
        </div>
        <div>
          <Button
            variant="link"
            size="empty"
            className="underline mt-4"
            onClick={() => setShowLearnMoreModal(true)}
          >
            Learn More
          </Button>
        </div>
      </Card>
    </>
  );
};

export default FinchDetails;
