import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormTextField, TextField } from '@/components/text-field.tsx';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@/components/ui/accordion.tsx';
import { Switch } from '@/components/ui/switch.tsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useAuth } from '@/providers/auth.tsx';
import { useState } from 'react';
import { formatCurrency } from '@/lib/utils.ts';

function formatPhoneNumber(phoneNumber: string) {
  const cleaned = phoneNumber.replace(/\D/g, '');
  if (cleaned.length !== 10) {
    return phoneNumber;
  }

  return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
}

const formSchema = z.object({
  first_name: z
    .string({
      required_error: 'First name is required',
    })
    .nonempty('First name is required'),
  last_name: z
    .string({
      required_error: 'Last name is required',
    })
    .nonempty('Last name is required'),
  email: z.string().email(),
  phone: z.string(),
  termsAccepted: z.boolean(),
});

const PrefillBlock = ({ label, value }: { label: string; value: string }) => {
  const [inputValue, setInputValue] = useState<string | undefined>(value);
  const [editable, setEditable] = useState(false);

  return (
    <div className="flex justify-between items-center border-b pb-2">
      <div>
        <p className="font-normal text-sm">{label}</p>
        {editable ? (
          <TextField
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        ) : (
          <p className="font-semibold py-1">{inputValue}</p>
        )}
        <Button
          variant="link"
          size="empty"
          type="button"
          className="underline"
          onClick={() => setEditable(!editable)}
        >
          {editable ? 'Save' : 'Edit'}
        </Button>
      </div>
      <div className="flex gap-x-2">
        <Switch />
        <p className="text-sm">Share This</p>
      </div>
    </div>
  );
};

export type TReferralFormSchema = z.infer<typeof formSchema>;

const ReferralForm = ({
  isEJ = false,
  loading,
  onSubmit,
}: {
  isEJ: boolean;
  loading: boolean;
  onSubmit: (data: TReferralFormSchema) => void;
}) => {
  const { user } = useAuth();

  const home = user?.user_detail.phone_number_home;
  const mobile = user?.user_detail.phone_number_mobile;
  const defaultPhone = home || mobile || '';

  const form = useForm<TReferralFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
      phone: formatPhoneNumber(defaultPhone),
    },
  });
  const variableAmount = isEJ ? '5000' : '40000';

  const handleSubmit = (data: TReferralFormSchema) => {
    onSubmit(data);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="overflow-y-scroll max-h-[500px] px-1">
          <p className="font-semibold mb-2">Contact Info</p>
          <div className="flex flex-col gap-y-4">
            <div className="flex gap-x-4">
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        label="First name"
                        error={form.formState.errors.first_name?.message}
                        test-id="first-name-input"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        label="Last name"
                        error={form.formState.errors.last_name?.message}
                        test-id="last-name-input"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex gap-x-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        label="Email"
                        error={form.formState.errors.email?.message}
                        test-id="email-input"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <FormTextField
                        label="Phone number"
                        error={form.formState.errors.phone?.message}
                        test-id="phone-input"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>

          <Accordion
            type="single"
            collapsible
            className="w-full bg-app-background px-4 my-8"
          >
            <AccordionItem value="item-1" className="border-none">
              <AccordionTrigger>
                <p>
                  <span className="font-semibold">
                    Share additional details
                  </span>{' '}
                  <span className="font-medium text-sm">(Optional)</span>
                </p>
              </AccordionTrigger>
              <AccordionContent>
                <div className="bg-card p-8">
                  <p>Based on our chat, we’ve prefilled additional info...</p>
                  <div className="flex flex-col gap-y-4 mt-4">
                    <PrefillBlock
                      label={isEJ ? 'Current education savings' : 'Debt amount'}
                      value={formatCurrency(variableAmount)}
                    />
                    <PrefillBlock label="State of residence" value="Texas" />
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
        <FormField
          control={form.control}
          name="termsAccepted"
          render={({ field }) => (
            <FormItem>
              <div className="flex flex-row items-start space-x-3 space-y-0 pt-2">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel className="text-md">
                    <p>
                      I agree to share my info with{' '}
                      {isEJ ? 'an advisor' : 'National Debt Relief'} so they can
                      reach out to me for a free consultation.
                    </p>
                    <FormMessage className="mt-2">
                      {form.formState.errors.termsAccepted?.message}
                    </FormMessage>
                  </FormLabel>
                </div>
              </div>
            </FormItem>
          )}
        />
        <Button className="w-full mt-8" type="submit" loading={loading}>
          Submit
        </Button>
      </form>
    </Form>
  );
};

export default ReferralForm;
