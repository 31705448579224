import { Card } from '@/components/ui/card.tsx';
import { ICensusItem, IFinchConnection } from '@/types.ts';
import { Badge } from '@/components/ui/badge.tsx';
import { BiCheckCircle, BiError } from 'react-icons/bi';
import { format } from 'date-fns';
import { useState } from 'react';
import FinchErrorsModal from '@/routes/dashboard/employees/finch/finch-errors-modal.tsx';
import { Button } from '@/components/ui/button.tsx';

interface FinchConnectedProps {
  connection: IFinchConnection;
  employeeCount?: number;
  finchEmployees?: ICensusItem[];
  lastSyncDate?: string;
}

const FinchImportErrorsBlock = ({
  count,
  detailsCallback,
}: {
  count: number;
  detailsCallback: () => void;
}) => {
  const pluralized = count > 1 ? 'employees' : 'employee';
  return (
    <div className="flex items-center gap-x-2 mt-2 border-l-[3px] border-warning pl-2 ml-4">
      <BiError className="text-warning" size={20} />
      <p>
        {count} {pluralized} couldn't be imported due to issues.{' '}
        <Button
          variant="link"
          size="empty"
          className="underline"
          onClick={detailsCallback}
        >
          View errors
        </Button>
      </p>
    </div>
  );
};

const FinchConnected = ({
  connection,
  employeeCount,
  finchEmployees = [],
  lastSyncDate,
}: FinchConnectedProps) => {
  const [showErrorsModal, setShowErrorsModal] = useState(false);
  const errorsList = [];

  for (const employee of finchEmployees) {
    if (employee.possible_errors.length > 0) {
      errorsList.push(employee);
    }
  }

  const handleDetailsClick = () => {
    setShowErrorsModal(!showErrorsModal);
  };

  return (
    <>
      <FinchErrorsModal
        errors={errorsList}
        open={showErrorsModal}
        onOpenChange={setShowErrorsModal}
        connection={connection}
      />
      <Card>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-x-4">
            <h2 className="text-xl font-semibold capitalize">
              {connection.finch_provider_id}
            </h2>
            <Badge variant="success">Connected</Badge>
          </div>
          {lastSyncDate ? (
            <p className="font-sm font-light">
              Last Sync{' '}
              <span className="font-semibold">
                {format(lastSyncDate, 'MM/dd/yyyy')}
              </span>
            </p>
          ) : null}
        </div>
        <div className="flex gap-x-2 mt-4">
          <BiCheckCircle className="text-success" size={20} />
          <p>{employeeCount} employees successfully imported.</p>
        </div>

        {errorsList.length > 0 ? (
          <FinchImportErrorsBlock
            count={errorsList.length}
            detailsCallback={handleDetailsClick}
          />
        ) : null}
      </Card>
    </>
  );
};

export default FinchConnected;
