import SplitLayout from '@/components/split-layout.tsx';
import { Link, useSearchParams } from 'react-router';
import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { IInvite } from '@/types.ts';
import { isBefore } from 'date-fns';
import { BiEnvelope } from 'react-icons/bi';
import AcceptInviteForm from '@/routes/invite/accept-invite-form.tsx';
import OTPCodeForm from '@/routes/login/otp-login/otp-code-form.tsx';

const checkIsExpired = (invite: any) => {
  const now = new Date();
  const expiration = new Date(invite.expires_at);
  return isBefore(expiration, now);
};

const ExpiredInviteSection = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-tan rounded-full p-2 mb-2">
        <BiEnvelope size={40} />
      </div>
      <h2 className="font-bold text-2xl">Let&apos;s Get You a New Invite</h2>
      <p>
        That invite's expired, but we can fix that! Contact your employer or
        email us at care@peopled.com for a new invite. We're here to help!
      </p>
    </div>
  );
};

const InviteAcceptedSection = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-tan rounded-full p-2 mb-2">
        <BiEnvelope size={40} />
      </div>
      <h2 className="font-bold text-2xl">Invite Already Used</h2>
      <p>
        This invitation has already been used.{' '}
        <Link className="underline" to="/login">
          Login to your account
        </Link>{' '}
        or email us at care@peopled.com if something has gone wrong. We're here
        to help!
      </p>
    </div>
  );
};

const Invite = () => {
  // Global Invite View State
  const [searchParams] = useSearchParams();
  const id: string | null = searchParams.get('id');
  const [invite, setInvite] = useState<IInvite | null>(null);
  const [expired, setExpired] = useState<boolean>(false);
  const [inviteAccepted, setInviteAccepted] = useState<boolean>(false);
  const [showEnterOTP, setShowEnterOTP] = useState<boolean>(false);
  // OTP Form State
  const [submittedEmail, setSubmittedEmail] = useState<string>('');
  const [requestCodeLoading, setRequestCodeLoading] = useState<boolean>(false);

  // Check to see if the id is present and valid in the searchParams
  useEffect(() => {
    sdk
      .getInvite(id!)
      .then((response) => {
        if (response.data.status_accepted) {
          setInviteAccepted(true);
        }
        if (checkIsExpired(response.data)) {
          setExpired(true);
        }
        setInvite(response.data);
      })
      .catch(() => {
        setExpired(true);
      });
  }, []);

  const handleSubmitEmail = async (data: IInvite) => {
    try {
      // Initially accept the invite
      await sdk.acceptInvite(data);
      // Once invite is accepted, automatically request a login code
      await sdk.createOTPCode(data.email);
      setSubmittedEmail(data.email);
      setShowEnterOTP(true);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const handleRequestCode = async (email: string) => {
    setRequestCodeLoading(true);
    try {
      await sdk.createOTPCode(email);
      setRequestCodeLoading(false);
    } catch (error) {
      setRequestCodeLoading(false);
    }
  };

  if (!invite) {
    return <div>Loading...</div>;
  }

  const invalidContent = () => {
    if (expired) {
      return <ExpiredInviteSection />;
    }

    if (inviteAccepted) {
      return <InviteAcceptedSection />;
    }
  };

  return (
    <SplitLayout
      leftContent={
        <h1 className="text-5xl font-semibold px-9 leading-tight">
          Let&apos;s Simplify Benefits Together.
        </h1>
      }
      rightContent={
        expired || inviteAccepted ? (
          invalidContent()
        ) : showEnterOTP ? (
          <OTPCodeForm
            email={submittedEmail}
            handleRequestCode={handleRequestCode}
            loading={requestCodeLoading}
          />
        ) : (
          <AcceptInviteForm invite={invite} onSubmit={handleSubmitEmail} />
        )
      }
    />
  );
};

export default Invite;
