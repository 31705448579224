import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Dispatch, SetStateAction } from 'react';
import Alert from '@/components/alert.tsx';
import EmployeeDetail from '@/routes/dashboard/employees/employee-detail.tsx';
import { IEmployee } from '@/types.ts';
import { Button } from '@/components/ui/button.tsx';

interface FinchEmployeeDetailsModalProps {
  reinviteLoading: boolean;
  handleClickReInvite: () => void;
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  employee: IEmployee | null;
}

const FinchEmployeeDetailsModal = ({
  reinviteLoading,
  handleClickReInvite,
  employee,
  open,
  onOpenChange,
}: FinchEmployeeDetailsModalProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[700px] w-full">
        <DialogTitle>Employee Details</DialogTitle>
        <DialogDescription className="hidden">
          Finch Employee Details
        </DialogDescription>
        <Alert
          description="To update this information, make changes directly in your payroll system. Your updates will sync automatically and appear here the next day."
          title="Need to make edits?"
        />

        <EmployeeDetail employee={employee} />

        {employee?.status !== 'registered' ? (
          <Button loading={reinviteLoading} onClick={handleClickReInvite}>
            Re-invite
          </Button>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default FinchEmployeeDetailsModal;
