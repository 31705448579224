import logo from '@/assets/images/logo/primary-orange.svg';
import { Link, useLocation } from 'react-router';
import { getTheme } from '@/lib/theme.ts';
import AvatarButton from '@/components/header/avatar-button.tsx';

export default function Header({
  hideButtons = false,
}: {
  hideButtons?: boolean;
}) {
  const theme = getTheme();
  const location = useLocation();

  // For the chat view, we need to use a fixed position to account for safari's bottom bar offsetting the content.
  // For the dashboard, we want to leave it as it is as all the sub-views depend on the dashboard's position.
  let userPathLocation;
  const position: Record<string, string> = {
    chat: 'fixed top-0 right-0 left-0',
    dashboard: 'sticky top-0',
  };

  if (location.pathname === '/app') {
    userPathLocation = 'chat';
  } else {
    userPathLocation = 'dashboard';
  }

  return (
    <header
      className={`border-outline ${position[userPathLocation]} border border-b-2 bg-white ${hideButtons ? 'py-4' : 'py-2'} px-4 z-[1]`}
    >
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <Link to="/">
            <img
              src={theme.logoUrl ? theme.logoUrl : logo}
              className="max-w-[133px] max-h-[45px]"
              alt="Logo"
            />
          </Link>
        </div>
        {!hideButtons && <AvatarButton />}
      </div>
    </header>
  );
}
