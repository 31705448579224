import { BiInfoCircle } from 'react-icons/bi';

interface AlertProps {
  description: string;
  title: string;
  variant?: 'info' | 'success' | 'warning' | 'danger';
}

const Alert = ({ description, title, variant = 'info' }: AlertProps) => {
  let icon;
  let color;

  switch (variant) {
    case 'info':
      color = 'bg-info-foreground/10';
      icon = <BiInfoCircle size={24} className="text-info-foreground" />;
      break;
    default:
      color = 'bg-info-foreground/10';
      icon = <BiInfoCircle size={24} className="text-info-foreground" />;
      break;
  }

  return (
    <div className={`${color} p-2`}>
      <div className="flex gap-x-2.5">
        <div>{icon}</div>
        <div className="text-sm">
          <p className="font-semibold">{title}</p>
          <p className="font-medium">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
