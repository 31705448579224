import { Card } from '@/components/ui/card.tsx';
import { Link } from 'react-router';
import { useAuth } from '@/providers/auth.tsx';
import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import userIcon from '@/assets/icons/user.svg';
import buildingIcon from '@/assets/icons/building.svg';
import Header from '@/components/header';

const ExperienceSwitcher = () => {
  const { user } = useAuth();
  const [organizationStatus, setOrganizationStatus] = useState(null);

  useEffect(() => {
    sdk.getOrganization(user.organization_id).then((response) => {
      setOrganizationStatus(response.data.organization_status);
    });
  }, []);

  return (
    <div className="flex flex-col h-full bg-app-background">
      <Header hideButtons />
      <div className="flex h-full justify-center items-center">
        <div className="max-w-[680px] flex flex-col">
          <div className="mb-8">
            <h1 className="text-2xl font-semibold">Welcome Back!</h1>
            <p className="text-lg">
              Looks like you have both admin and employee access.
            </p>
          </div>
          <div className="flex flex-col gap-y-2">
            <p>Choose the role you’d like to access:</p>
            <Link
              className="flex-1"
              onClick={() =>
                localStorage.setItem('experiencePreference', 'dashboard')
              }
              to="/dashboard"
            >
              <Card className="flex items-center gap-x-4">
                <img className="w-9" src={buildingIcon} alt="Building Icon" />
                <div>
                  <h3 className="text-lg font-bold">Admin</h3>
                  <p>Set up your company’s benefits and invite employees.</p>
                </div>
              </Card>
            </Link>
            <Link
              className="flex-1"
              onClick={() =>
                localStorage.setItem('experiencePreference', 'chat')
              }
              to="/app"
            >
              <Card className="flex items-center gap-x-4">
                <img className="w-9" src={userIcon} alt="User Icon" />
                <div>
                  <h3 className="text-lg font-bold flex items-center gap-x-4">
                    Employee{' '}
                    {organizationStatus === 'onboarding' && (
                      <span className="text-sm bg-tan py-1 px-2.5 rounded-lg">
                        Preview Available
                      </span>
                    )}
                  </h3>
                  {organizationStatus === 'onboarding' && (
                    <p>
                      Preview your employee experience. Finish setup to enable
                      access for all employees.
                    </p>
                  )}
                  {organizationStatus === 'active' && (
                    <p>Access your employee benefits and resources.</p>
                  )}
                </div>
              </Card>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceSwitcher;
