import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Textarea } from '@/components/ui/textarea.tsx';
import { Button } from '@/components/ui/button.tsx';

export default function ChatInput({
  label = '',
  placeholder,
  loading,
  onSubmit,
}: {
  label?: string;
  loading?: boolean;
  onSubmit: (_value: string) => void;
  placeholder?: string;
}) {
  const [value, setValue] = useState<string>('');

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    onSubmit(value);
    setValue('');
  };

  return (
    <>
      <div className="rounded flex relative">
        <div className="flex-1">
          <Textarea
            disabled={loading}
            value={value}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setValue(e.target.value)
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
            autoFocus={true}
            test-id="chat-input"
            placeholder={placeholder ?? 'Type your message here'}
            className="font-medium text-base resize-none border border-input placeholder:text-slate-300 placeholder:font-normal chat-input focus-visible:ring-transparent pr-[45px]"
          />
          <p className="text-sm pt-2">{label}</p>
        </div>
        <div className="absolute right-2 top-2">
          <Button
            test-id="chat-cta"
            variant="cta"
            disabled={!value}
            loading={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
      <p className="text-muted-foreground text-sm mb-2">
        Anonymous by design. Chats are private and never shared without
        permission.
      </p>
    </>
  );
}
