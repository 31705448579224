import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import { cn, formatCurrency } from '@/lib/utils';

const ProgressDebtRelief = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, ...props }, ref) => {
  const percentage = ((value as number) / props.max!) * 100;
  return (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(
        'relative h-6 w-full overflow-hidden bg-neutral-200 rounded-full',
        className
      )}
      {...props}
    >
      <p className="absolute z-10 left-3 text-white font-semibold">
        {formatCurrency(value)}
      </p>
      <ProgressPrimitive.Indicator
        className="h-full w-full rounded-full flex-1 bg-[#0077E3] transition-all"
        style={{ transform: `translateX(-${100 - percentage}%)` }}
      />
    </ProgressPrimitive.Root>
  );
});
ProgressDebtRelief.displayName = ProgressPrimitive.Root.displayName;

export { ProgressDebtRelief };
