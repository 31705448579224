import { Card } from '@/components/ui/card.tsx';
import { BiCheck, BiCheckCircle } from 'react-icons/bi';
import { Button } from '@/components/ui/button.tsx';
import ejAdvisor from '@/assets/images/mocks/ej-advisor.png';
import ejLogo from '@/assets/images/mocks/ej-logo.png';
import { useState } from 'react';
import ReferralModal from '@/components/chat/referral/referral-modal.tsx';

const DetailsSection = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className="flex text-sm">
      <div className="flex gap-x-2">
        <div>
          <BiCheck size={24} className="text-[#FDE57F]" />
        </div>
        <div>
          <p className="font-semibold py-1">{title}</p>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const EJCard = () => {
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [referralSubmitted, setReferralSubmitted] = useState(false);

  return (
    <Card>
      <ReferralModal
        isEJ={true}
        onOpenChange={setShowReferralModal}
        open={showReferralModal}
        submitCallback={() => {
          setReferralSubmitted(true);
          setShowReferralModal(false);
        }}
      />
      <div className="flex justify-center mt-4 mb-2">
        <img src={ejLogo} alt="logo" className="w-[130px]" />
      </div>
      <div className="text-center mt-4">
        <h3 className="text-lg font-semibold">
          A smarter way to save for John&apos;s education
        </h3>
        <p>See how a 529 Plan can help.</p>
      </div>
      <div className="flex px-8 mt-8">
        <div className="flex-1 px-2">
          <DetailsSection
            title="Tax-free growth"
            description="Your savings grow tax-free when used for qualified education
              expenses."
          />
        </div>
        <div className="flex-1 px-2">
          <DetailsSection
            title="Flexible use"
            description="Funds can be used for tuition, books, housing and even k-12 private school expenses."
          />
        </div>
      </div>

      <div className="px-10 mt-4">
        <Card>
          <div className="flex justify-between">
            <div className="flex border-r items-center">
              <img src={ejAdvisor} alt="Edward Jones Advisor" />
              <div className="px-4">
                <p className="text-xs font-medium">Financial Advisor</p>
                <p className="text-lg font-semibold">Julia Bartak</p>
                <p className="text-xs font-medium">CFP&reg;</p>
              </div>
            </div>
            <div className="flex items-center">
              {!referralSubmitted ? (
                <Button
                  className="bg-[#FDE57F] text-primary"
                  onClick={() => setShowReferralModal(true)}
                >
                  Request a 529 consultation
                </Button>
              ) : (
                <div className="text-[#1E7E34] font-semibold">
                  <div className="flex flex-col max-w-[200px] items-center text-center">
                    <BiCheckCircle size={32} />
                    <p className="text-sm">Consultation request submitted!</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
};

export default EJCard;
