import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { useParams } from 'react-router';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';

const Files = () => {
  const params = useParams() as {
    organizationId: string;
    documentId: string;
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    sdk
      .downloadOrganizationDocument(params.organizationId, params.documentId)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        // @TODO handle error?
        setLoading(false);
        console.error('error: ', error);
      });
  }, []);
  if (loading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner size={100} />
      </div>
    );
};

export default Files;
