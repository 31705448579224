import Header from '@/components/header';
import { useAuth } from '@/providers/auth.tsx';
import { Navigate } from 'react-router';
import Chat from '@/components/chat';

function App() {
  const { user } = useAuth();

  // @TODO maybe make a hook or utility for serializing/deserializing a full userPreferences object for localStorage
  // @TODO or even consider a server-side preference object if the need for more preferences arises
  const experiencePreference = localStorage.getItem('experiencePreference');

  if (user.role === 'admin') {
    if (!experiencePreference) return <Navigate to="/experience-switcher" />;
    if (experiencePreference === 'dashboard')
      return <Navigate to="/dashboard" />;
  }

  // Onboarding flow for new users, only shown during the first time participant (chat) experience
  const showOnboarding = !user.onboarding_complete;
  if (showOnboarding) return <Navigate to="/onboarding" />;

  return (
    <div className="bg-app-background flex flex-col h-screen overflow-hidden">
      <Header />
      <Chat />
    </div>
  );
}

export default App;
