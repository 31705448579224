import Header from '@/components/header';
import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { IMetrics, IOrganization } from '@/types.ts';
import { useAuth } from '@/providers/auth.tsx';
import { Outlet, useLocation } from 'react-router';
import Sidebar from '@/routes/dashboard/sidebar.tsx';
import Intercom from '@intercom/messenger-js-sdk';
import ActiveDashboard from '@/routes/dashboard/components/active-dashboard.tsx';
import OnboardingDashboard from '@/routes/dashboard/components/onboarding-dashboard.tsx';

const Dashboard = () => {
  const { user } = useAuth();
  const location = useLocation();
  const isBaseRoute = location.pathname === '/dashboard';
  const [organization, setOrganization] = useState<IOrganization | null>(null);
  const [metrics, setMetrics] = useState<IMetrics | null>(null);

  useEffect(() => {
    sdk.getMetrics().then((response) => setMetrics(response.data));
    sdk
      .getOrganization(user.organization_id)
      .then((response) => setOrganization(response.data))
      .catch((error) => console.error(error));
  }, []);

  // Initialize Intercom widget
  useEffect(() => {
    if (user) {
      const full_name = user.first_name + ' ' + user.last_name;
      const createdAt =
        typeof user.created_at === 'string'
          ? Math.floor(new Date(user.created_at).getTime() / 1000)
          : user.created_at;

      Intercom({
        app_id: 's1par89m',
        user_id: user.id,
        name: full_name,
        email: user.email,
        created_at: createdAt,
      });
    }
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [user]);

  if (!organization) {
    return <p>Loading...</p>;
  }

  const visibleDashboard = () => {
    switch (organization.organization_status) {
      case 'active':
        return <ActiveDashboard metrics={metrics} />;
      case 'onboarding':
        return <OnboardingDashboard employeeCount={metrics?.total_employees} />;
      default:
        return <OnboardingDashboard employeeCount={metrics?.total_employees} />;
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />

      <div className="flex flex-1">
        <Sidebar organization={organization} />
        <main className="flex-1 p-8 bg-app-background">
          {isBaseRoute && (
            <div>
              <h1 className="text-2xl font-semibold mb-7">Dashboard</h1>
              {visibleDashboard()}
            </div>
          )}
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
