import { SyntheticEvent, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { useEmployeesStore } from '@/routes/dashboard/employees/store.ts';
import { Card } from '@/components/ui/card.tsx';
import { DataTable } from '@/components/data-table.tsx';
import { columns } from '@/routes/dashboard/employees/columns.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import AddEmployeeForm from '@/routes/dashboard/employees/add-employee-form.tsx';
import { IEmployee, IInvite } from '@/types.ts';
import EmployeeDetail from '@/routes/dashboard/employees/employee-detail.tsx';
import EditEmployeeForm from '@/routes/dashboard/employees/edit-employee-form';
import { sdk } from '@/api.ts';
import FinchEmployeeDetailsModal from '@/routes/dashboard/employees/finch/finch-employee-details-modal.tsx';
import EmployeesToolbar from '@/routes/dashboard/employees/employees-toolbar.tsx';
import Finch from '@/routes/dashboard/employees/finch';
import { useFinchStore } from '@/routes/dashboard/employees/finch/store.ts';

const Employees = () => {
  const {
    employees,
    searchQuery,
    statusFilter,
    pagination,
    handlePageChange,
    fetchEmployees,
  } = useEmployeesStore();

  const { checkFinchConnected, checkFinchEnabled } = useFinchStore();

  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showEditEmployee, setShowEditEmployee] = useState(false);
  const [showFinchEmployeeDetails, setShowFinchEmployeeDetails] =
    useState(false);
  const [reinviteLoading, setReinviteLoading] = useState(false);
  const [reInviteStatusMessage, setReInviteStatusMessage] = useState('');
  const [editable, setEditable] = useState(false);
  const [editableEmployee, setEditableEmployee] = useState<IEmployee | null>(
    null
  );

  const showReInviteButton =
    editableEmployee?.status === 'invited' ||
    editableEmployee?.status === 're-invited';

  useEffect(() => {
    fetchEmployees(pagination.page);
  }, [pagination.page, searchQuery]);

  // When status filter changes, fetch employees with the new status filter and
  // reset the page to be on page 1
  useEffect(() => {
    fetchEmployees(1);
  }, [statusFilter]);

  const handleSearchChange = (e: SyntheticEvent) => {
    setTimeout(() => {
      useEmployeesStore.setState({
        searchQuery: (e.target as HTMLInputElement).value,
      });
    }, 700);
  };

  const onAddEmployeeSuccess = () => {
    fetchEmployees(pagination.page);
    setShowAddEmployee(false);
  };

  const handleClickRow = (row: any) => {
    setEditableEmployee(row);

    if (checkFinchConnected()) {
      setShowFinchEmployeeDetails(true);
    } else {
      setShowEditEmployee(true);
    }
  };

  const handleCloseEditableModal = () => {
    setEditableEmployee(null);
    setEditable(false);
    setShowEditEmployee(false);
  };

  const handleClickCancel = () => {
    setEditable(false);
  };

  const handleSuccessfulEdit = () => {
    fetchEmployees(pagination.page);
    handleCloseEditableModal();
  };

  const handleClickReInvite = () => {
    setReinviteLoading(true);
    const resendableInvite: IInvite = {
      id: editableEmployee!.invite_id,
      first_name: editableEmployee!.first_name,
      last_name: editableEmployee!.last_name,
      email: editableEmployee!.email,
      role: editableEmployee!.role,
    };
    sdk
      .resendInvites([resendableInvite])
      .then(() => {
        setReinviteLoading(false);
        setReInviteStatusMessage('Re-invite sent successfully');
      })
      .catch(() => {
        setReinviteLoading(false);
        setReInviteStatusMessage('Failed to send re-invite');
      });
  };

  return (
    <>
      {/* Finch Employee Details Dialog */}
      <FinchEmployeeDetailsModal
        reinviteLoading={reinviteLoading}
        handleClickReInvite={handleClickReInvite}
        employee={editableEmployee}
        open={showFinchEmployeeDetails}
        onOpenChange={setShowFinchEmployeeDetails}
      />

      {/* Add Dialog */}
      <Dialog open={showAddEmployee} onOpenChange={setShowAddEmployee}>
        <DialogContent>
          <DialogTitle>Add Employee</DialogTitle>
          <DialogDescription className="hidden">Add Employee</DialogDescription>
          <AddEmployeeForm
            role="user"
            handleClose={() => setShowAddEmployee(false)}
            handleSuccess={onAddEmployeeSuccess}
          />
        </DialogContent>
      </Dialog>
      {/* Edit Dialog */}
      <Dialog open={showEditEmployee} onOpenChange={handleCloseEditableModal}>
        <DialogContent className="max-w-screen-md">
          <DialogTitle>View Employee</DialogTitle>
          <DialogDescription className="hidden">
            View Employee
          </DialogDescription>
          {editable ? (
            <EditEmployeeForm
              employee={editableEmployee}
              handleClose={handleCloseEditableModal}
              handleCancel={handleClickCancel}
              handleSuccess={handleSuccessfulEdit}
            />
          ) : (
            <EmployeeDetail employee={editableEmployee} />
          )}

          <div>
            {!editable ? (
              <div className="flex gap-x-8 items-center">
                <div className="flex gap-x-2">
                  <Button onClick={() => setEditable(true)}>Edit</Button>
                  {showReInviteButton ? (
                    <Button
                      loading={reinviteLoading}
                      variant="outline"
                      onClick={handleClickReInvite}
                    >
                      Re-invite
                    </Button>
                  ) : null}
                </div>
                {reInviteStatusMessage ? (
                  <div className="text-success">{reInviteStatusMessage}</div>
                ) : null}
              </div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
      <h1 className="text-2xl font-semibold mb-4">Employees</h1>
      <Finch />
      <EmployeesToolbar
        finchEnabled={checkFinchEnabled()}
        finchConnected={checkFinchConnected()}
        handleSearchChange={handleSearchChange}
        showModalCallback={() => setShowAddEmployee(true)}
      />
      <Card className="mt-4">
        <DataTable
          onPageChange={handlePageChange}
          pagination={pagination}
          columns={columns}
          data={employees}
          rowClickCallback={handleClickRow}
        />
      </Card>
    </>
  );
};

export default Employees;
