import { create } from 'zustand';
import { sdk } from '@/api.ts';
import { ICensusItem, IFinchConnection, IFinchLogin } from '@/types';

interface FinchStore {
  connection: IFinchConnection | null;
  employees: ICensusItem[];
  handleAccessCode: (code: string) => void;
  loading: boolean;
  login: IFinchLogin | null;
  init: () => void;
  checkFinchConnected: () => boolean;
  checkFinchEnabled: () => boolean;
  checkFinchNeedsReauth: () => boolean;
}

export const useFinchStore = create<FinchStore>((set, get) => ({
  connection: null,
  employees: [],
  loading: false,
  login: null,
  handleAccessCode: async (code: string) => {
    set({ loading: true });

    try {
      await sdk.postFinchLogin({ access_code: code });
      await sdk.postFinchEmployees();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const [employeesResponse, loginResponse, connectionResponse] =
        await Promise.all([
          sdk.getFinchEmployees(),
          sdk.getFinchLogin(),
          sdk.getFinchConnection(),
        ]);
      // Store connection data for checking
      const connectionData = connectionResponse.data;
      
      set({
        connection: connectionData,
        employees: employeesResponse.data.employees,
        login: loginResponse.data,
        // Only set loading to false when we're completely done with processing
        loading: false
      });
      
      // If we don't immediately have a valid connection, force a refresh after a delay
      if (!(connectionData?.connection_id && connectionData?.status_active && connectionData?.access_token)) {
        // Wait a bit longer and try refreshing the connection data
        setTimeout(async () => {
          try {
            const refreshedConnection = await sdk.getFinchConnection();
            set({ connection: refreshedConnection.data });
          } catch (err) {
            console.error('Error refreshing connection:', err);
          }
        }, 1500);
      }
    } catch (error) {
      set({ loading: false });
      console.error(error);
    }
  },
  init: async () => {
    try {
      set({ loading: true });

      const [connectionResponse, loginResponse, finchEmployeesResponse] =
        await Promise.all([
          sdk.getFinchConnection(),
          sdk.getFinchLogin(),
          sdk.getFinchEmployees(),
        ]);

      set({
        connection: connectionResponse.data,
        employees: finchEmployeesResponse.data.employees,
        login: loginResponse.data,
        loading: false,
      });
    } catch (error) {
      set({ loading: false });
      console.error('Error fetching Finch configuration: ', error);
    }
  },
  checkFinchConnected: () => {
    return !!(
      get().connection &&
      get().connection?.connection_id &&
      get().connection?.status_active &&
      get().connection?.access_token
    );
  },
  checkFinchEnabled: () => {
    return !!get().login && !get().login?.connected;
  },
  checkFinchNeedsReauth: () => {
    return !!(
      get().login &&
      get().login?.error &&
      get().login?.error.includes('No access token. Reauthentication needed')
    );
  },
}));
