import { Card } from '@/components/ui/card.tsx';
import { BiCheck } from 'react-icons/bi';
import ndrLogo from '@/assets/images/mocks/logo-ndr.png';
import ndrRatings from '@/assets/images/mocks/ndr-demo-ratings.png';
import { Button } from '@/components/ui/button.tsx';
import ReferralModal from '@/components/chat/referral/referral-modal.tsx';
import { useEffect, useState } from 'react';
import ChatResponse from '@/components/chat/chat-response.tsx';
import { ProgressDebtRelief } from '@/components/progress-debt-relief.tsx';

const ReferralCard = () => {
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [referralSubmitted, setReferralSubmitted] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => setProgress(24920), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Card>
        <ReferralModal
          onOpenChange={setShowReferralModal}
          open={showReferralModal}
          submitCallback={() => {
            setReferralSubmitted(true);
            setShowReferralModal(false);
          }}
        />
        <div className="flex justify-center">
          <img src={ndrLogo} alt="NDR Logo" />
        </div>
        <div className="flex flex-col items-center mt-4 py-2">
          <p className="text-[#0077E3] font-semibold">
            Pay up to 38% less than you owe
          </p>
          <div className="max-w-[351px] w-full mt-2 mb-6">
            <div className="flex gap-x-2 items-center">
              <ProgressDebtRelief value={progress} max={40000} />
              <p className="font-semibold">$40,000</p>
            </div>
          </div>
          <ul>
            <li className="flex items-center">
              <BiCheck className="text-[#0077E3] mr-2" size={24} /> Debt free in
              as little as 12-48 months
            </li>
            <li className="flex items-center">
              <BiCheck className="text-[#0077E3] mr-2" size={24} /> Free
              consultation and zero up-front Fees
            </li>
          </ul>
        </div>

        <div className="flex mb-4 justify-center">
          <Button
            variant="link"
            className="underline"
            onClick={() =>
              window.open(
                'https://nationaldebtrelief.com',
                '_blank',
                'noopener,noreferrer'
              )
            }
          >
            Learn more
          </Button>
        </div>

        <div className="flex bg-app-background justify-center items-center my-2 py-2">
          <img src={ndrRatings} alt="" />
        </div>

        <div className="flex justify-center my-4">
          {!referralSubmitted ? (
            <Button onClick={() => setShowReferralModal(true)}>
              Start your free consultation
            </Button>
          ) : null}
        </div>
      </Card>
      {referralSubmitted ? (
        <ChatResponse
          message={`Great! We shared your contact info and a specialist will be in touch soon! \n\n Is there anything else I can help you with?`}
          type="bot"
        />
      ) : null}
    </>
  );
};

export default ReferralCard;
