import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Alert from '@/components/alert.tsx';
import { ICensusItem, IFinchConnection } from '@/types.ts';
import { format, parseISO } from 'date-fns';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Card } from '@/components/ui/card.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx';
import { Badge } from '@/components/ui/badge.tsx';

interface FinchErrorsModalProps {
  connection: IFinchConnection;
  errors: ICensusItem[];
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
}

function capitalize(str: string): string {
  if (!str) return str;

  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const formatDate = (date: string): string => {
  if (!date) return '';
  return format(parseISO(date), 'M/d/yyyy h:mm a');
};

const FinchErrorsModal = ({
  connection,
  errors,
  open,
  onOpenChange,
}: FinchErrorsModalProps) => {
  const [checked, setChecked] = useState(false);
  const [openItems, setOpenItems] = useState<string[]>([]);

  useEffect(() => {
    if (checked) {
      setOpenItems(errors.map((_, index) => `item-${index}`));
    } else {
      setOpenItems([]);
    }
  }, [checked, errors]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[700px] w-full bg-app-background max-h-[90vh] overflow-hidden flex flex-col">
        {' '}
        <DialogTitle>View Errors</DialogTitle>
        <DialogDescription className="hidden">
          View errors for employees that couldn't be imported due to issues.
        </DialogDescription>
        <Alert
          description={`Once changes are made in ${capitalize(connection.finch_provider_id)}, they will automatically sync overnight. Check back after the next data sync to see if errors are resolved.`}
          title="The following issues need to be fixed your payroll system"
        />
        <div className="flex justify-between">
          <p>
            Last sync{' '}
            <span className="font-semibold">
              {formatDate(connection.data_refreshed_at)}
            </span>
          </p>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="expand"
              checked={checked}
              onCheckedChange={(value) => setChecked(!!value)}
            />
            <label
              htmlFor="expand"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Expand all errors
            </label>
          </div>
        </div>
        <div className="px-3 flex-1 overflow-y-auto">
          <Card>
            <Accordion
              type="multiple"
              value={openItems}
              onValueChange={setOpenItems}
              className="w-full"
            >
              {errors.map((error, index) => {
                const pluralizedErrors =
                  error.possible_errors.length > 1 ? 'errors' : 'error';
                const itemId = `item-${index}`;

                return (
                  <AccordionItem key={itemId} value={itemId}>
                    <AccordionTrigger>
                      <div className="flex gap-x-2">
                        <p>{`${error.first_name} ${error.last_name}`}</p>
                        <Badge variant="pending">
                          {error.possible_errors.length} {pluralizedErrors}
                        </Badge>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Issue Type</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Suggested Fix</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {error.possible_errors.map((pError) => (
                            <TableRow key={pError.field_name}>
                              <TableCell className="font-medium">
                                {pError.field_name}
                              </TableCell>
                              <TableCell>{pError.error_description}</TableCell>
                              <TableCell>{pError.suggested_fix}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionContent>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Card>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FinchErrorsModal;
