import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import {
  BiChat,
  BiChevronDown,
  BiIdCard,
  BiInfoCircle,
  BiLogOut,
  BiTransfer,
  BiUser,
} from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router';
import { IUser } from '@/types.ts';
import { SyntheticEvent } from 'react';
import { useAuth } from '@/providers/auth.tsx';

const getFirstLetters = (f?: string, l?: string) => {
  const first = f?.charAt(0) || '';
  const last = l?.charAt(0) || '';
  return first + last;
};

const getFullName = (user: IUser) => {
  return `${user.first_name || ''} ${user.last_name || ''}`;
};

const AvatarButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();

  const isAdmin = user.role === 'admin';
  const isDashboard = location.pathname.includes('dashboard');

  const handleChangeExperience = () => {
    if (isDashboard) {
      localStorage.setItem('experiencePreference', 'chat');
      navigate('/app');
    } else {
      localStorage.setItem('experiencePreference', 'dashboard');
      navigate('/dashboard');
    }
  };

  const doLogout = async (e: SyntheticEvent) => {
    e.preventDefault();
    logout();
    navigate('/');
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        test-id="avatar-button"
        className="flex items-center"
      >
        <div className="rounded-full w-12 h-12 bg-primary text-primary-foreground flex justify-center items-center">
          {getFirstLetters(user.first_name, user.last_name)}
        </div>
        <BiChevronDown className="h-5 w-5" />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={`min-w-[224px] ${!isDashboard ? 'mr-4' : ''}`}
      >
        <DropdownMenuLabel>{getFullName(user)}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {!isDashboard && (
          <DropdownMenuItem
            test-id="user-profile-menu-link"
            onClick={() => navigate('/app/account/personal')}
          >
            <BiUser />
            Profile
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          test-id="user-contact-menu-link"
          onClick={() => navigate('/app/account/contact')}
        >
          <BiIdCard />
          Contact Info
        </DropdownMenuItem>
        {!isDashboard && (
          <DropdownMenuItem
            test-id="user-chat-history-menu-link"
            onClick={() => navigate('/app/account/chat-history')}
          >
            <BiChat />
            Chat History
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          test-id="user-help-menu-link"
          onClick={() => navigate('/help')}
        >
          <BiInfoCircle />
          Help
        </DropdownMenuItem>
        {isAdmin && (
          <DropdownMenuItem
            test-id="user-experience-menu-link"
            onClick={handleChangeExperience}
          >
            <BiTransfer />
            {isDashboard ? 'Employee Experience' : 'Admin Experience'}
          </DropdownMenuItem>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem test-id="user-logout-menu-link" onClick={doLogout}>
          <BiLogOut />
          Log out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AvatarButton;
