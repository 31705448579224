import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog.tsx';
import ReferralForm, {
  TReferralFormSchema,
} from '@/components/chat/referral/referral-form.tsx';
import { useState } from 'react';
import Alert from '@/components/alert.tsx';

interface IReferralModalProps {
  isEJ?: boolean;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  submitCallback: () => void;
}

const ReferralModal = ({
  isEJ = false,
  open,
  onOpenChange,
  submitCallback,
}: IReferralModalProps) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitReferral = (data: TReferralFormSchema) => {
    setSubmitLoading(true);

    setTimeout(() => {
      setSubmitLoading(false);
      submitCallback();
    }, 1000);
    // No need for this log, but keeping it for reference
    console.log('data: ', data);
  };
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen-sm">
        {isEJ ? (
          <DialogTitle>Request a 529 Plan consultation</DialogTitle>
        ) : (
          <DialogTitle>Get your free debt relief consultation</DialogTitle>
        )}
        <Alert
          variant="info"
          title="Review &amp; share your details"
          description={`${isEJ ? 'An advisor' : 'A debt relief specialist'} will contact you
          soon. Share more details now for a faster, more tailored consultation.`}
        />
        <ReferralForm
          isEJ={isEJ}
          loading={submitLoading}
          onSubmit={handleSubmitReferral}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ReferralModal;
